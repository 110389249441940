<template>
  <aside class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
         style="width: var(--menu-width); min-height: 100vh;">
    <img src="@/assets/logo.png" alt="Общегородской контакт-центр">
    <hr>
    <user-menu/>
    <hr>
    <ul class="nav nav-pills flex-column">
      <nav-link to="calls" name="Вызовы" icon="bi-telephone-inbound"/>
      <nav-link to="blacknumbers" name="Запр.номера" icon="bi-telephone-x"/>
      <nav-link to="reports" name="Отчеты" icon="bi-file-bar-graph"/>
      <nav-link to="tasks" name="Файлы" icon="bi-file-arrow-down"/>
      <hr v-if="privileged">
      <nav-link v-if="privileged" to="templates" name="Шаблоны" icon="bi-journal-text"/>
      <nav-link v-if="privileged" to="blacklists" name="Блэклисты" icon="bi-journal-x"/>
      <hr v-if="privileged">
      <nav-link v-if="privileged" to="projects" name="Компании" icon="bi-people-fill"/>
      <nav-link v-if="superadmin" to="users" name="Пользователи" icon="bi-person-lines-fill"/>
      <nav-link v-if="privileged" to="codes" name="Проекты" icon="bi-gear-fill"/>
      <hr v-if="privileged">
      <nav-link v-if="privileged || privilege?.dialog_designer" to="scripts" name="Сценарии" icon="bi-diagram-3-fill"/>

    </ul>
    <scroll-button/>

  </aside>
</template>

<script>
import UserMenu from "./UserMenu";
import NavLink from "./NavLink";
import {mapGetters} from "vuex";
import ScrollButton from "@/navigation/ScrollButton";

export default {
  components: {ScrollButton, NavLink, UserMenu},
  computed: {
    ...mapGetters('auth', ['privileged', 'privilege', 'superadmin'])
  }
}
</script>

<style>
:root {
  --menu-width: 12rem !important;
}
</style>
