<template>
  <div class="d-flex flex-column align-items-center" style="cursor: pointer">
    <icon-button class="fs-4" icon="bi-download" title="Скачать результаты"/>
    <small style="font-size: 0.4em">{{ total }}</small>
  </div>
</template>

<script>
import IconButton from "@/components/IconButton";

export default {
  components: {IconButton},
  props:[
      "total",
  ],
}
</script>
