<!--eslint-disable vue/no-mutating-props-->
<template>
  <fl-form>
    <fl-group name="Соединение">
      <fl-input v-model="data.name" label="Название" :disabled="!!data.autoname"/>
      <fl-input v-model="data.autoname" type="checkbox" label="Автоназвание"/>
      <fl-input v-model="data.priority" type="number" label="Приоритет"/>
      <fl-input v-model="data.type" type="select" label="Тип перехода">
        <option value="default">По умолчанию</option>
        <option v-if="links.includes('error')" value="error">Ошибка</option>
        <option v-if="links.includes('error2')" value="error">Повторная ошибка</option>
        <option v-if="links.includes('values')" value="values">Проверка значения</option>
        <option v-if="links.includes('logic')" value="logic">Проверка условия</option>
        <option v-if="links.includes('time')" value="time">Проверка времени</option>
      </fl-input>
    </fl-group>
    <fl-group v-if="data.type=='values'" name="Значения">
      <fl-input v-model="type" type="select" label="Вид проверки">
        <option value="equal">Равно</option>
        <option value="in">Входит</option>
        <option value="not_in">Не входит</option>
        <option value="pcre2">Рег.выраж.</option>
      </fl-input>
      <fl-input v-model="chip" label="Значение" @keydown.enter.prevent="addChip"/>
      <chips :data="data.equal" cls="bg-primary" @clicked="chipClicked($event, 'equal')"/>
      <chips :data="data.in" cls="bg-warning" @clicked="chipClicked($event, 'in')"/>
      <chips :data="data.not_in" cls="bg-info" @clicked="chipClicked($event, 'not_in')"/>
      <chips :data="data.pcre2" cls="bg-success" @clicked="chipClicked($event, 'pcre2')"/>
    </fl-group>

    <fl-group v-if="data.type=='logic'" name="Логика перехода">
      <fl-input v-model="data.ops_logic" type="select">
        <option :value="undefined">ИЛИ (режим совместимости)</option>
        <option value="or">ИЛИ</option>
        <option value="and">И</option>
        <option value="lex">Сложное выражение</option>
      </fl-input>
      <fl-ops
          v-if="data.ops_logic!='lex'"
          :data="data.ops"
          @do-add="data.ops.push({lvalue: '%var_10%', op:'==', rvalue: '100'})"
      />
      <lex v-else :data="data.subdata" :level="0" :nondeletable="true"/>
    </fl-group>
    <!--    <fl-group v-if="data.type=='logic'" name="Логика перехода">-->
    <!--      <fl-input v-model="data.ops_logic" type="select">-->
    <!--        <option :value="undefined">ИЛИ (режим совместимости)</option>-->
    <!--        <option value="or">ИЛИ</option>-->
    <!--        <option value="and">И</option>-->
    <!--        <option value="lex">Сложное выражение</option>-->
    <!--      </fl-input>-->
    <!--      <fl-ops-->
    <!--          v-if="data.ops_logic!='lex'"-->
    <!--          :data="data.ops"-->
    <!--          @do-add="data.ops.push({lvalue: '%var_10%', op:'==', rvalue: '100'})"-->
    <!--      />-->
    <!--    </fl-group>-->


    <fl-group v-if="data.type=='time'" name="Диапазон времени">
      <fl-input v-model="data.tfrom" label="От"/>
      <fl-input v-model="data.tto" label="До"/>
    </fl-group>
  </fl-form>

</template>

<script>
import FlForm from "./components/FlForm";
import FlGroup from "./components/FlGroup";
import FlInput from "./components/FlInput";
import Chips from "../../../components/Chips";
import FlOps from "./components/FlOps";
import Lex from "@/pages/flow/forms/lex/Lex";

export default {
  components: {Lex, FlOps, Chips, FlInput, FlGroup, FlForm},
  props: ["data", "links"],
  data() {
    return {
      chip: "",
      type: "equal",
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.makeName();
      }
    }
  },

  created() {
    // поддержка обратной совместимости #BCR
    // eslint-disable-next-line vue/no-mutating-props
    if (this.data.pcre2 === undefined) this.data.pcre2 = [];

    // поддержка обратной совместимости #BCR
    if (this.data.subdata === undefined) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.subdata = {
        ops_logic: 'or',
        ops: [],
        subops: [],
      }
    }

    this.makeName();
  },

  methods: {
    addChip() {
      if (this.type == 'pcre2') {
        // eslint-disable-next-line vue/no-mutating-props
        if (this.chip) this.data[this.type].push(this.chip);
      } else {
        this.chip.split(',').forEach(i => {
          // eslint-disable-next-line vue/no-mutating-props
          if (i.trim()) this.data[this.type].push(i.trim());
        })
      }
      this.chip = '';
      // e.stopPropagation();
    },

    chipClicked(event, chipType) {
      if (event.where == 'body') {
        this.type = chipType;
        this.chip = this.data[chipType][event.index];
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.data[chipType].splice(event.index, 1)
    },

    makeName() {
      if (!this.data.autoname) return;
      if (this.data.type == "default") {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.name = "По умолчанию";
      } else if (this.data.type == "error") {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.name = "Ошибка";
      } else if (this.data.type == "values") {
        // eslint-disable-next-line vue/no-mutating-props
        let result = "Проверка значения";
        if (this.data.equal.length)
          result = this.data.equal[0]
        else if (this.data.in.length)
          result = this.data.in[0]
        else if (this.data.not_in.length)
          result = this.data.not_in[0]
        else if (this.data.pcre2.length)
          result = this.data.pcre2[0]
        // eslint-disable-next-line vue/no-mutating-props
        this.data.name = result;
      } else if (this.data.type == "logic") {
        if (this.data.ops_logic == 'lex') {
          // eslint-disable-next-line vue/no-mutating-props
          this.data.name = "Сложное выражение";
        } else if (this.data.ops.length > 0) {
          const a = [];
          for (const o of this.data.ops) a.push('' + o.lvalue + o.op + o.rvalue)
          // eslint-disable-next-line vue/no-mutating-props
          this.data.name = a.join(this.data.ops_logic == 'and' ? ' и ' : ' или ');
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.data.name = "Задайте логику"
        }
      } else if (this.data.type == "time") {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.name = this.data.tfrom + '-' + this.data.tto;
      }

    }
  },
}
</script>

