<!--eslint-disable vue/no-mutating-props-->

<template>
  <fl-group name="Параметры ASR">
    <fl-select v-model="data.settings.asr_params.provider" label="Провайдер" :options="$options.asr.providers"/>
    <fl-select v-model="data.settings.asr_params.language" label="Язык" :options="$options.asr.langs[data.settings.asr_params.provider]"/>
    <fl-select v-model="data.settings.asr_params.grammar" label="Словарь" :options="$options.asr.grammar[data.settings.asr_params.provider]"/>
  </fl-group>
</template>

<script>
import FlGroup from "@/pages/flow/forms/components/FlGroup";
import FlSelect from "@/pages/flow/forms/components/FlSelect";

export default {
  components: {FlGroup, FlSelect},
  props: ["data"],
  asr: {
    providers: {
      yandex: "Яндекс",
      tinkoff: "Тинькофф",
      true_google: "Google",
      asm: "ASM Solutions",
      speechpro: "Центр речевых технологий",
      salute: "Сбер Салют",
    },
    langs: {
      yandex: {
        'ru-RU': "Русский",
        'en-US': "Английский",
        'tr-TR': "Турецкий",
      },
      tinkoff: {
        'ru-RU': "Русский",
        'en-US': "Английский",
      },
      true_google: {
        'en-US': "Английский",
        'ru-RU': "Русский",
      },
      asm: {
        'ru-RU': "Русский",
      },
      speechpro: {
        'ru-RU': "Русский",
      },
      salute: {
        'ru-RU': "Русский",
      },
    },
    grammar: {
      yandex: {
        'general': "По умолчанию",
        'silero': "Silero VAD",
      },
      tinkoff: {
        'any': "По умолчанию",
        'silero': "Silero VAD",
      },
      true_google: {
        'eng': "По умолчанию",
        'silero': "Silero VAD",
      },
      asm: {
        'chat': "По умолчанию",
        'postcode': "Индексы",
        'geo': "Адреса",
        'track': "Track-номера",
        'rus_f15_t162mic_phone6': "Русский (нужно выбрать)",
        'aze_f15_t722': "Азербайджанский",
        'kir_f15_t768_rus': "Киргизский+Русский",
        'kir_f15_t687': "Киргизский",
        'tgk_f15_t420': "Таджикский",
        'uzb_f15_mixse4_rus': "Узбекский+Русский",
        'uzb_f15_t511': "Узбекский",
      },
      speechpro: {
        'Recognize_IvrFarFieldRus12': "По умолчанию",
        'Recognize_IvrRus12': "Альтернативная",
      },
      salute: {
        'any': "По умолчанию",
      },
    }
  }

}
</script>
