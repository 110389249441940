<template>
  <div class="d-flex gap-2 align-items-center">
    <div v-if="error" class="text-danger">{{ error }}</div>

    <span v-if="loading" class="spinner-grow spinner-grow-sm"/>

    <div v-if="plan" class="d-flex gap-2">
      <f-input v-model="number" type="select" label="Установить на номер">
        <option v-for="p in plan" :key="p" :value="p.number">{{ p.number }} - {{ p.scripting_name }}</option>
      </f-input>
      <button @click="onDeploy" class="btn btn-primary">Установить</button>
    </div>

  </div>
</template>

<script>
import FInput from "@/components/FInput";
import {http} from "@/misc/http";

export default {
  components: {FInput},
  props: ["script_id"],
  data() {
    return {
      plan: null,
      number: null,
      error: null,
      loading: false,
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.plan = await http.get("/deploy");
      if (this.plan.length == 0) {
        this.plan = null;
      } else {
        this.number = this.plan[0].number;
      }
    } catch (e) {
      this.error = e.message;
    }
    this.loading = false;
  },
  methods: {
    async onDeploy() {
      if (!confirm("Вы уверены что хотите установить скрипт " + this.script_id + " на номер " + this.number + "?"))
        return;
      try {
        this.loading = true;
        await http.post("/deploy", {script_id: this.script_id, deploy_number: this.number});
        this.loading = false;
        setTimeout(
            ()=>alert("Экспорт и установка начаты, проверьте раздел файлы."),
            100,
        )

      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },

}
</script>

